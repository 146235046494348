.container-aboutus {
    padding: 64px;
  }
  
  .row-aboutus:after {
    content: "";
    display: table;
    clear: both
  }
  
  .column-66-aboutus {
    float: left;
    width: 66.66666%;
    padding: 20px;
    text-align: justify;
  }
  
  .column-33-aboutus {
    float: left;
    width: 33.33333%;
    padding: 20px;
  }
  
  .large-font {
    font-size: 48px;
  }
  
  .xlarge-font {
    font-size: 64px
  }
  
  .button-extra {
    border: none;
    color: white;
    padding: 14px 28px;
    font-size: 16px;
    background-color: #04AA6D;
  }
  
  .img-aboutus {
    display: block;
    height: auto;
    max-width: 100%;
  }
  
  @media screen and (max-width: 1000px) {
    .column-66-aboutus,
    .column-33-aboutus {
      width: 100%;
      text-align: center;

    }
    .img-aboutus {
      margin: auto;
    }
  }