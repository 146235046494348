.mainMainContainer{
    height:100vh;
    margin-bottom:10%;
    
}

.mainHeading {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 3.5s steps(40, end),
      blink-caret .75s step-end infinite;
    font-weight: 600;
    padding-top: 2%;
    font-family: Brush Script MT, Brush Script Std, cursive;
}
    @media (min-width: 50px) and (max-width: 480px) { 
    .mainHeading{
      white-space: normal; /* Keeps the content on a single line */
      animation: none;
      font-size: 20px;
      margin-bottom: 5%;
    }
    .mainMainContainer{
      margin-bottom:25%;      
    }
  }
  
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }