.contactUsMainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  gap: 30px;
}

.socialLinks:before,
a:after {
  content: '';
  position: absolute;
  transition: 0.5s;
}
.socialMediaLogoMain {
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  justify-content: center;
  align-items: center;
  text-align: center;}
.socialMediaLogo {
  list-style: none;
  flex: 0 0 200px;
}

.socialMediaLogo:nth-child(1) a {
  background-color: #25d366;
}
.socialMediaLogo:nth-child(1) a:before {
  background-color: #25d366;
}
.socialMediaLogo:nth-child(1) a:after {
  background-color: #6EFF70;
}
.socialMediaLogo:nth-child(2) a {
  background-color: #1877f2;
}
.socialMediaLogo:nth-child(2) a:before {
  background-color: #0d6ae4;
}
.socialMediaLogo:nth-child(2) a:after {
  background-color: #2b82f3;
}
.socialMediaLogo:nth-child(3) a {
  background-color: #c32aa3;
}
.socialMediaLogo:nth-child(3) a:before {
  background-color: #ae2591;
}
.socialMediaLogo:nth-child(3) a:after {
  background-color: #d22fb0;
}
.socialMediaLogo:nth-child(4) a {
  background-color: #1da1f2;
}
.socialMediaLogo:nth-child(4) a:before {
  background-color: #0d95e8;
}
.socialMediaLogo:nth-child(4) a:after {
  background-color: #30a9f3;
}
.socialMediaLogo:nth-child(5) a {
  background-color: #f00;
}
.socialMediaLogo:nth-child(5) a:before {
  background-color: #e60000;
}
.socialMediaLogo:nth-child(5) a:after {
  background-color: #ff1414;
}

.socialMediaLogo:nth-child(6) a {
  background-color:  #008B8B;
}
.socialMediaLogo:nth-child(6) a:before {
  background-color:  #008B8B;
}
.socialMediaLogo:nth-child(6) a:after {
  background-color:  #00CED1;
}

.socialMediaLogo:hover .fab {
  color: #fff;
}
.socialLinks {
  position: relative;
  width: 120px;
  height: 120px;
  text-align: center;
  box-shadow: -20px 20px 10px rgba(0, 0, 0, 0.5);
  transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(0, 0);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialLinks:before {
  top: 10px;
  left: -20px;
  height: 100%;
  width: 20px;
  transform: rotate(0deg) skewY(-45deg);
}
.socialLinks:after {
  bottom: -20px;
  left: -10px;
  height: 20px;
  width: 100%;
  transform: rotate(0deg) skewX(-45deg);
}
.socialLinks:hover {
  transform: perspective(1000px) rotate(-30deg) skew(25deg)
    translate(20px, -20px);
  box-shadow: -50px 50px 50px rgba(0, 0, 0, 0.5);
  text-decoration: none;
}
.fab {
  line-height: 80px;
  transition: 0.5s;
}


@media screen and (max-width: 900px) {
  .socialMediaLogoMain {
    flex-direction: column;
    gap: 50px;
  }
  .contactUsMainContainer {
    margin-top: 30%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}